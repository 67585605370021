import React from "react"

import Layout from "../components/layout"
import ImageMeta from "../components/ImageMeta"
import TheaterVideo from "@bit/azheng.joshua-tree.theater"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

const pickleball = () => {
  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={"en"}>
        <SEO
          lang="en"
          title={`Watch Dr. Chien's Demonstration Video`}
          description="Watch Dr. Chien's Demonstration Video"
        />

        <div
          style={{
            textAlign: "center",
            marginTop: "140px",
            padding: "0 50px",
          }}
        >
          <h1 style={{ fontSize: "2.5rem" }}>
            Watch Dr. Chien's Demonstration Video
          </h1>
        </div>

        <div
          style={{
            padding: "0 40px 60px 40px",
            width: "100%",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          <TheaterVideo
            videoUrl={`https://www.youtube.com/watch?v=DA9N7QcndTQ`}
            overVideo={true}
            language={"en"}
            buttonClass="contained"
          >
            <ImageMeta
              cloudName="nuvolum"
              publicId="FOMS/DEV/chisel_dr_chien"
              width="auto"
              className="theater-image"
            ></ImageMeta>
          </TheaterVideo>

          <p style={{ marginTop: "2rem" }}>
            During COVID-19, concerns of aerosolization increased when using a
            surgical handpiece for dental extractions. Dr. Chien created a video
            demonstrating the use of a chisel and mallet for wisdom tooth
            extraction, an alternative method to be considered. This video was
            published in the June 2021 issue of the Journal of Oral and
            Maxillofacial Surgery.
          </p>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default pickleball
